import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enTranslation from './languages/en.json'
import kaTranslation from './languages/ka.json'

const resources = {
  en: { translation: enTranslation },
  ka: { translation: kaTranslation },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'ka',
  interpolation: {
    escapeValue: false,
  },
})

export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language)
}

// export const { t } = useTranslation()

export default i18n
