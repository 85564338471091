import { useEffect } from 'react';

const useAdjustHeight = () => {
  useEffect(() => {
    const adjustHeight = () => {
      //@ts-ignore
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isIOS && window.innerWidth < 756) {
        document.body.style.height = `${window.innerHeight}px`;
      }
    };

    // Adjust height when the keyboard opens and closes
    window.addEventListener('resize', adjustHeight);

    // Call it initially to set the correct height
    adjustHeight();

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);
};

export default useAdjustHeight;
