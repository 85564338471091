/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { t } from "i18next";
import { ICategory, IModels } from "types/category";
// import { Button } from "components/styled/Button";
// import Dropdown from "components/styled/Dropdown";
import Input from "components/styled/Input";
import searchIcon from "assets/icons/searchIcon.svg";
import { ReactComponent as ArrowDown } from "assets/images/CaretDownDark.svg";
import { FilterList } from "./styled";
import Checkbox from "../checkbox/checkbox";
import { currentLangUrl } from "helpers";

interface IProps {
  categories?: ICategory[];
  models?: IModels[];
  filters: number[];
  onActive?: (slug: string) => void;
  setFilters: (data: number[]) => void;
}

const CarFilter: FC<IProps> = ({
  categories,
  models = [],
  filters,
  onActive,
  setFilters,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const cat = searchParams.get("cat");
  // const type = searchParams.get("type");
  const { id } = useParams();

  const [showModels, setshowModels] = useState(true);
  const [showAllModels, setshowAllModels] = useState(false);
  const [searchModels, setSearchModels] = useState("");
  const [filteredModels, setFilteredModels] = useState(models);
  const [activeCategories, setActiveCategories] = useState<string[]>([]);

  useEffect(() => {
    if (cat) {
      onActive && onActive(cat);
    }
  }, [cat]);

  useEffect(() => {
    setFilteredModels(models);
  }, [models]);

  useEffect(() => {
    if (searchModels) {
      setFilteredModels(
        models.filter((i) =>
          i.title.toLowerCase().includes(searchModels.toLowerCase())
        )
      );
    } else {
      setFilteredModels(models);
    }
  }, [searchModels]);

  useEffect(() => {
    if (id) {
      setActiveCategories([id]);
    }
  }, [id]);

  return (
    <>
      <FilterList className="mb-600 mt-600">
        {!!categories?.length &&
          categories.map((item) => (
            <div key={item.id}>
              <div className="category pb-100">
                <div
                  onClick={() =>
                    navigate(`${currentLangUrl()}/products/${item.slug}`)
                  }
                >
                  {item.title}
                </div>

                {!!item?.children?.length && (
                  <div
                    onClick={() =>
                      setActiveCategories((state) =>
                        activeCategories.includes(item.slug) ||
                        activeCategories.some(
                          (i) => item?.children?.some((j) => j.slug === i)
                        )
                          ? state.filter(
                              (i) =>
                                i !== item.slug &&
                                item?.children?.length &&
                                !item?.children.some((j) => j.slug === i)
                            )
                          : [...state, item.slug]
                      )
                    }
                  >
                    <ArrowDown
                      className={`${
                        activeCategories.includes(item.slug) ||
                        activeCategories.some(
                          (i) => item?.children?.some((j) => j.slug === i)
                        )
                          ? "arrow-up"
                          : ""
                      } cursor-pointer`}
                    />
                  </div>
                )}
              </div>

              {!!item?.children?.length &&
                (activeCategories.includes(item.slug) ||
                  activeCategories.some(
                    (i) => item?.children?.some((j) => j.slug === i)
                  )) &&
                item?.children.map((sub) => (
                  <div
                    className={`sub-category pb-150 pl-200 ${
                      id === sub.slug ? "active" : ""
                    }`}
                    key={sub.id}
                    onClick={() => {
                      navigate(`${currentLangUrl()}/products/${sub.slug}`);
                    }}
                  >
                    {sub.title}
                  </div>
                ))}
            </div>
          ))}

        {!!models.length && (
          <div className="mt-500 mb-300">
            <Input
              preIcon={searchIcon}
              placeholder={`${t("search")}...`}
              value={searchModels}
              onChange={(e) => setSearchModels(e.target.value)}
            />
          </div>
        )}

        {!!filteredModels.length && (
          <>
            <div>
              <div className="display-flex align-items-center justify-space-between mb-200">
                <div className="category">{t("model")}</div>

                <div onClick={() => setshowModels((state) => !state)}>
                  <ArrowDown
                    className={`${
                      !showModels ? "arrow-up" : ""
                    } cursor-pointer`}
                  />
                </div>
              </div>

              {showModels && (
                <>
                  {!!filteredModels?.length &&
                    filteredModels.length > 4 &&
                    !showAllModels && (
                      <>
                        {filteredModels.slice(0, 4).map((item) => (
                          <Checkbox
                            key={item.id}
                            label={item.title}
                            checked={filters.includes(item.id)}
                            onCheck={() =>
                              setFilters(
                                filters.includes(item.id)
                                  ? filters.filter((i) => i !== item.id) || []
                                  : [...filters, item.id]
                              )
                            }
                          />
                        ))}

                        <div
                          className="showAllModels"
                          onClick={() => setshowAllModels(true)}
                        >
                          {t("seeAll")}
                        </div>
                      </>
                    )}
                  {((!!filteredModels?.length && filteredModels.length <= 4) ||
                    showAllModels) && (
                    <>
                      {filteredModels.map((item) => (
                        <Checkbox
                          key={item.id}
                          label={item.title}
                          checked={filters.includes(item.id)}
                          onCheck={() =>
                            setFilters(
                              filters.includes(item.id)
                                ? filters.filter((i) => i !== item.id) || []
                                : [...filters, item.id]
                            )
                          }
                        />
                      ))}

                      {filteredModels.length > 4 && (
                        <div
                          className="showAllModels"
                          onClick={() => setshowAllModels(false)}
                        >
                          {t("rollUp")}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </FilterList>
    </>
  );
};

export default CarFilter;
