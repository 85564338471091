/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '..'

const getContactData = () => {
  return axiosInstance.get('contacts').then((res) => {
    return res.data
  })
}

const send = (data: any) => {
  return axiosInstance.post('contacts/store', data).then((res) => {
    return res
  })
}

const search = (text: string) => {
  return axiosInstance.get(`search?keyword=${text}`).then((res) => {
    return res
  })
}

export const contact = {
  search,
  getContactData,
  send: send,
}
