import { FC, useRef, useState, useEffect } from "react";
import { BlogCardContainer } from "./styled";
import { Link } from "react-router-dom";
import { currentLangUrl } from "helpers";

interface IProps {
  title: string;
  url: string;
  description: string;
  img: string;
  isSmall?: boolean;
}

const BlogCard: FC<IProps> = ({ title, url, description, img, isSmall }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (imgRef.current) {
        setImgWidth(imgRef?.current?.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [imgRef?.current?.offsetWidth]);

  return (
    <Link to={currentLangUrl() + "/blog/" + url}>
      <BlogCardContainer className={isSmall ? "isSmall" : ""}>
        <div className="img-container" style={{ height: imgWidth / 1.5 }}>
          <img ref={imgRef} src={img} alt={title} />
        </div>

        <div>
          <h3>{title}</h3>
          {!!description && <p>{description}</p>}
        </div>
      </BlogCardContainer>
    </Link>
  );
};

export default BlogCard;
