import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { currentLangUrl } from "helpers";

interface IProps {
  url: string;
  hidemore?: boolean;
}

const DiscoverMore: FC<IProps> = ({ url, hidemore }) => {
  const { t } = useTranslation();

  return (
    <Link to={currentLangUrl() + url}>
      <Button variant="base" size="lg">
        {t("discoverMore")}
      </Button>
    </Link>
  );
};

export default DiscoverMore;
