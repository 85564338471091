import { t } from "i18next"
import { NotFoundContainer } from "./styled"
import { Button } from "components/styled/Button"

const NotFound = () => {
    return (
        <NotFoundContainer>
            <div className="container">
                <h1>404</h1>
                <h2>{t('pageNotFound')}</h2>
                <Button className="mt-800" variant="primary" size="xl" link='/'>
                    {t('returnToHome')}
                </Button>
            </div>
        </NotFoundContainer>
    )
}

export default NotFound