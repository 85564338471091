import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { API } from "services/API/API";
import {
  IHomeBlog,
  IHomeCover,
  IHomeProducts,
  IHomeProject,
  IHomeSlider,
  IHomeVlog,
} from "types/home";
import { useCanonicalUrl } from "services/hooks/canonical";
import HomeCars from "components/parts/home/cars";
import HomeBlogs from "components/parts/home/blogs";
import HomeVlogs from "components/parts/home/videos";
import HomeSlider from "components/parts/home/slider";
import Loader from "components/layout/Loader";
import Offers from "components/parts/home/offers";
import HomeProjects from "components/parts/home/projects";

import { HomePageContainer } from "./styled";
import { IListItem } from "components/parts/news";

const HomeBigSection = React.lazy(
  () => import("components/parts/home/bigSection")
);

const Homepage = () => {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [cover, setCover] = useState<IHomeCover>();
  const [blogs, setBlogs] = useState<IHomeBlog[]>([]);
  const [vlogs, setVlogs] = useState<IHomeVlog[]>([]);
  const [productCategories, setProductCategories] = useState<IHomeProducts[]>(
    []
  );
  const [offers, setOffers] = useState<IHomeProject[]>([]);
  const [slider, setSliders] = useState<IHomeSlider[]>([]);
  const [products, setProducts] = useState<IListItem[]>([]);
  const url = useCanonicalUrl();

  const text =
    localStorage.getItem("jcb_text") ||
    "1945 წლიდან, JCB ქმნის სპეც ტექნიკას, რომლის დახმარებითაც შესაძლებელია ფართო სპექტრის სამუშაოების შესრულება. მსოფლიოში ცნობილი და პოპულარული ტექნიკა გამოირჩევა არამარტო მაღალი ხარისხით, არამედ, უსაფრთხოებით, ეკოლოგიურობით, ინოვაციურობითა და კომფორტით.";

  const [meta, setMeta] = useState({
    title: `${t("main")} | ${ProjectTitle}`,
    description: text,
    canonical: url,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "JCB, საგზაო, სამშენებლო, სასაწყობო, სასოფლო-სამეურნეო, ექსკავატორი, ტრაქტორი, დამტვირთველი",
      },
      property: {
        "og:title": ProjectTitle,
        "og:description": text,
        "og:type": "website",
        "og:url": url,
        "og:image": "https://back.jcb.ge/storage/125/3CX-G.jpg",
        "og:image:width": "800",
        "og:image:height": "800",
        "og:site_name": "JCB",
        "twitter:card": "summary_large_image",
        "twitter:site": "@yoursite",
        "twitter:title": ProjectTitle,
        "twitter:description": text,
        "twitter:image": "https://back.jcb.ge/storage/125/3CX-G.jpg",
      },
    },
  });

  const lang = i18n.language;

  useEffect(() => {
    API.home.getHomeData().then((res) => {
      setProducts(
        res?.products?.map(
          (i: {
            url: string;
            slug: string;
            img: string;
            description: string;
            price: string;
            media: { url: string };
          }) => {
            i.url = i.slug;
            i.img = i.media?.url;
            return i;
          }
        ) || []
      );
      setBlogs(res?.blogs);
      setVlogs(
        res?.vlogs.map((item: any) => {
          return {
            ...item,
            description: "",
            url: item.slug,
            video:
              item.video_source === "youtube"
                ? item.youtube_url
                : item?.file?.url,
            img: item?.media?.url,
            youtubeUrl: item.video_source === "youtube" ? item.youtube_url : "",
            videoUrl:
              item.video_source === "youtube"
                ? item.youtube_url
                : item?.file?.url,
          };
        })
      );
      setSliders(res?.sliders);
      setProductCategories(res?.productCategories.slice(0, 4));
      setCover(res?.cover[0]);
      setOffers(
        res?.offers?.map((item: { [x: string]: { url: string }; img: any }) => {
          item.img = item["main-image"]?.url || "";
          return item;
        })
      );
      setLoader(false);

      console.log(res.metas?.meta_image?.url);

      if (res.metas) {
        setMeta((state) => ({
          ...state,
          title: `${res.metas?.meta_title} | ${ProjectTitle}`,
          meta: {
            ...state.meta,
            property: {
              ...state.meta.property,
              "og:image": res.metas?.meta_image?.url
                ? res.metas?.meta_image?.url
                : "https://back.jcb.ge/storage/125/3CX-G.jpg",
            },
          },
        }));
      }
    });
  }, [lang]);

  return (
    <DocumentMeta {...meta}>
      <link
        rel="preload"
        as="image"
        href=" https://back.jcb.ge/storage/449/Hydradig-gare.jpg"
      />
      {loader && <Loader />}
      <HomePageContainer>
        <section className="mb-1000">
          <HomeSlider slider={slider} />
        </section>

        <div className="container">
          {productCategories && (
            <section className="mb-1000">
              <HomeCars products={productCategories} hidemore scroll />
            </section>
          )}
        </div>

        <section
          className="grey-bg pt-1000 pb-1000"
          style={{ overflow: "hidden" }}
        >
          <Offers offers={offers} />
        </section>

        <div className="container pt-1000 pb-1000">
          <HomeBlogs blogs={blogs} />
        </div>

        {!!cover && cover?.media?.url && (
          <section>
            <HomeBigSection cover={cover} />
          </section>
        )}

        {vlogs && vlogs.length > 0 && (
          <div className="grey-bg">
            <div className="container pt-1000 pb-1000">
              <HomeVlogs vlogs={vlogs} />
            </div>
          </div>
        )}

        <section style={{ overflow: "hidden" }}>
          <HomeProjects projects={products} discoverMore />
        </section>
      </HomePageContainer>
    </DocumentMeta>
  );
};

export default Homepage;
