import ListItem, { IListItem } from "../news";
import DiscoverMore from "components/styled/DiscoverMore";
import { FC, useEffect, useState } from "react";
import { SimilarProjects } from "pages/Projects/styled";
import { useTranslation } from "react-i18next";
import useDeviceType from "helpers";
import { SliderContainer } from "pages/Homepage/styled";
import Slider from "react-slick";
import { ReactComponent as ArrowLeft } from "assets/icons/CaretLeft.svg";

interface IProps {
  projects: IListItem[];
  title?: string;
  discoverMore?: boolean;
}

export const deviceBreakpoints = {
  mobile: 480,
  tablet: 744,
  md: 1280,
  lg: 1680,
};

const HomeProjects: FC<IProps> = ({ projects, title, discoverMore }) => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const [screenSizes, setScreenSizes] = useState<number>(1);
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [lastSlide, setLastSlide] = useState(false);
  const [perSlide, setPerSlide] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (
        screenWidth >= deviceBreakpoints.tablet &&
        screenWidth < deviceBreakpoints.md
      ) {
        setScreenSizes(2.5);
        setPerSlide(2);
      } else if (
        screenWidth >= deviceBreakpoints.md &&
        screenWidth < deviceBreakpoints.lg
      ) {
        setScreenSizes(3.5);
        setPerSlide(3);
      } else {
        setScreenSizes(4.5);
        setPerSlide(4);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const beforeChange = (_: number, next: number) => {
    setSlideIndex(Math.round(next));
  };

  function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
      <button className="slick-next" onClick={onClick} disabled={lastSlide}>
        <ArrowLeft />
      </button>
    );
  }

  function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        className="slick-prev"
        onClick={onClick}
        disabled={+slideIndex === 0}
      >
        <ArrowLeft />
      </button>
    );
  }

  let settings = {
    dots: false,
    arrows: deviceType === "mobile" ? false : true,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: screenSizes,
    slidesToScroll: 1,
    initialSlide: 0,
    afterChange: (current: number) => {
      if (current.toString().includes(".")) {
        setActiveSlide(1);
      } else {
        setActiveSlide(0);
      }
    },
    beforeChange,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  if (projects.length < 4) {
    settings.slidesToShow = projects.length;
  }

  useEffect(() => {
    if (+projects?.length - perSlide - +slideIndex) {
      setLastSlide(false);
    } else {
      setLastSlide(true);
    }
  }, [projects?.length, perSlide, slideIndex]);

  return (
    <>
      <SimilarProjects className="mt-1000">
        {!!projects?.length && (
          <div className="container">
            <h2 className="mt-0 mb-300">
              {title ? title : t("findDesiredModel")}
            </h2>
          </div>
        )}

        {!!projects?.length && (
          <>
            {deviceType === "mobile" ? (
              <div className="display-flex flex-column gap-300 similar-mobile">
                {projects.map((item, index) => (
                  <div key={`room-${index}`}>
                    <ListItem
                      showDesc
                      superSmallImg
                      {...item}
                      url={`/product/${item.url}`}
                      projectsList
                    />
                  </div>
                ))}
              </div>
            ) : (
              <SliderContainer>
                <div className={`container activeSlide-${activeSlide}`}>
                  <div className="slider-services">
                    <Slider {...settings}>
                      {projects.map((item, index) => (
                        <div key={`room-${index}`}>
                          <ListItem
                            showDesc
                            superSmallImg
                            {...item}
                            url={`/product/${item.url}`}
                            projectsList
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </SliderContainer>
            )}
          </>
        )}
      </SimilarProjects>

      {discoverMore && (
        <div className="display-flex justify-center mt-600">
          <DiscoverMore url="/products" />
        </div>
      )}
    </>
  );
};

export default HomeProjects;
