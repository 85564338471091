import { globalStyles } from 'consts/spaces'
import styled from 'styled-components'

interface IProps {
  center?: boolean
}

export const SubTitle = styled.h2<IProps>`
  color: ${(props) => props.theme.colorTextPrimary};
  margin: 0;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  margin-bottom: ${globalStyles.spaces[600]};

  &.mb-400 {
    margin-bottom: ${globalStyles.spaces[400]};
  }

  &.mb-300 {
    margin-bottom: ${globalStyles.spaces[300]};
  }

  a {
    float: right;
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    margin-bottom: ${globalStyles.spaces[400]} !important;
    text-align: center;

    &.left {
      text-align: left;
    }
  }
`
