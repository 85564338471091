import { Button } from "components/styled/Button";
import Dropdown from "components/styled/Dropdown";
import { Title } from "components/styled/Title";
import { t } from "i18next";
import { BlogFilterContainer } from "./styled";
import { FC, useState } from "react";
import { ICategory } from "types/category";

interface IProps {
  pageTitle: string;
  showSort: boolean;
  category: ICategory[];
  onActive: (slug: string) => void;
}

const BlogFilter: FC<Partial<IProps>> = ({
  pageTitle,
  showSort,
  category,
  onActive,
}) => {
  const [active, setActive] = useState<string>("all");
  const dropdownItems = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const handleDropdownSelect = (selectedValue: string) => {
    console.log(`Selected: ${selectedValue}`);
  };

  return (
    <BlogFilterContainer className="mb-500">
      {pageTitle && <Title className="text-center">{t(pageTitle)}</Title>}

      <div className="blog-cats">
        <div className="display-flex gap-200 justify-center mt-500">
          <Button
            variant={active === "all" ? "primary" : "base"}
            size="lg"
            onClick={() => {
              onActive && onActive("");
              setActive("all");
            }}
          >
            {t("all")}
          </Button>
          {category &&
            category.map((item) => (
              <Button
                onClick={() => {
                  onActive && onActive(item.slug);
                  setActive(item.slug);
                }}
                key={item.id}
                variant={active === item.slug ? "primary" : "base"}
                size="lg"
              >
                {item.title}
              </Button>
            ))}
        </div>
      </div>

      {showSort && (
        <div className="display-flex justify-flex-end">
          <Dropdown
            label={t("blog.filter.sort")}
            items={dropdownItems}
            onSelect={handleDropdownSelect}
          />
        </div>
      )}
    </BlogFilterContainer>
  );
};

export default BlogFilter;
