import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const BlogCardContainer = styled.div`
  &::after {
    content: "";
    clear: both;
    display: table;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  div.img-container {
    position: relative;
    border-radius: 0;
    overflow: hidden;
    margin-bottom: ${globalStyles.spaces[200]};
    height: 420px;

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      height: 190px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
  }

  h3 {
    margin: 0;
    color: ${(props) => props.theme.colorTextPrimary};
    width: 100%;
    max-height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: block;
    display: -webkit-box;
  }

  p {
    margin: 0;
    margin-top: 8px;
    color: ${(props) => props.theme.colorTextSubtle} !important;
    opacity: 0.7;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      width: 100%;
      font-size: ${globalStyles.fonts.p14};
    }
  }

  a {
    margin-top: ${globalStyles.spaces[100]};
    display: block;
    color: ${(props) => props.theme.colorTextDanger};

    &:hover {
      color: #e12439;
      text-decoration: underline;
    }
  }

  &.isSmall {
    div {
      flex: 2;
    }

    p {
      width: 100%;
      // height: 45px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      display: block;
      display: -webkit-box;
      word-wrap: break-word;
    }

    & > div.img-container {
      height: 248px;
      flex: 1;

      @media (max-width: ${globalStyles.breackPoints.lg}) {
        height: 173px;
      }

      @media (max-width: ${globalStyles.breackPoints.md}) {
        height: 320px;
      }

      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        height: 336px;
      }

      @media (max-width: ${globalStyles.breackPoints.mobile}) {
        height: 216px;
      }
    }

    h3 {
      font-size: ${globalStyles.fonts.b20};
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      display: block;

      h3 {
        font-size: ${globalStyles.fonts.p16};
        width: 100%;
      }

      & > div.img-container {
        margin: 0 0 ${globalStyles.spaces[200]} 0;
      }
    }
  }
`;
