import { IListItem } from "components/parts/news";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import i18n from "locales/i18n";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const removeHtmlTags = (input: string) => {
  const regex = /<[^>]*>/g;
  return input.replace(regex, "");
};

export const dataConvertStandart = (arr: any[]): IListItem[] => {
  return arr.map((item) => {
    return {
      ...item,
      img: item["main-image"]?.url,
      description: removeHtmlTags(item.short_description),
    };
  });
};

const deviceBreakpoints = {
  mobile: 768,
  tablet: 1280,
};

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState<"desktop" | "mobile" | "tablet">(
    "desktop"
  );

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < deviceBreakpoints.mobile) {
        setDeviceType("mobile");
      } else if (
        screenWidth >= deviceBreakpoints.mobile &&
        screenWidth < deviceBreakpoints.tablet
      ) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return deviceType;
};

export default useDeviceType;

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const useOnClickOutside = (ref: any, handler: (e: Event) => void) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const currentLangUrl = () => {
  if (i18n.language === "en") {
    return "/en";
  }
  return "";
};
