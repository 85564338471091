/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { OffersContainer } from "./styled";
import { useEffect, useState } from "react";
import ListItem, { IListItem } from "components/parts/news";
import { API } from "services/API/API";
import { dataConvertStandart } from "helpers";
import { useCanonicalUrl } from "services/hooks/canonical";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { BlogList } from "pages/Blog/styled";
import BlogFilter from "components/parts/news/filter";
import { ICategory } from "types/category";
import Pagination from "components/parts/pagination";

const Offers = () => {
  const { t, i18n } = useTranslation();
  const [data, setdata] = useState<IListItem[]>([]);
  const [cat, setCurrentCat] = useState("");
  const [category, setCategory] = useState<ICategory[]>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const [totalPage, setTotalPage] = useState(9);

  const fetchData = () => {
    API.offers.getOffers(cat).then((res) => {
      setPerPage(0);
      setTotalPage(0);

      setdata(
        dataConvertStandart(
          res.data.data.map((item: { url?: string; slug: string }) => {
            item.url = "/offers/" + item?.slug;
            return item;
          })
        )
      );
    });
  };

  const getCategories = () => {
    API.offers.getCategories().then((res) => {
      setCategory(res);
    });
  };

  useEffect(() => {
    fetchData();
    getCategories();
  }, [cat, i18n.language]);

  const url = useCanonicalUrl();
  const meta = {
    title: `${t("offers")} | ${ProjectTitle}`,
    description: t("offers"),
    canonical: url,
  };

  const onPageChange = (e: number) => {
    const element = document.getElementById("page-container");
    element?.scrollIntoView({ behavior: "smooth" });
    setPage(e);
  };

  return (
    <DocumentMeta {...meta}>
      <OffersContainer id="page-container">
        <BlogFilter
          pageTitle="offers"
          onActive={(e) => setCurrentCat(e)}
          category={category}
        />

        <BlogList className="row container">
          {data.map((item, index) => (
            <div
              className="col-3 col-sm-12 col-md-6 col-lg-4"
              key={`offers-${index}`}
            >
              <ListItem withoutspace smallImg maxDescription {...item} />
            </div>
          ))}
        </BlogList>

        {totalPage > perPage && (
          <div className="container">
            <Pagination
              onChange={(e) => onPageChange(e)}
              page={page}
              perPage={perPage}
              totalPage={totalPage}
              className="container"
            />
          </div>
        )}
      </OffersContainer>
    </DocumentMeta>
  );
};

export default Offers;
