import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "services/API/API";
import Input from "components/styled/Input";
import { Button } from "components/styled/Button";
import { ContactFormContainer } from "./styled";
import { useNavigate } from "react-router-dom";
import { currentLangUrl } from "helpers";

const ContactForm = ({ countryCodes }: { countryCodes: string[] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
    country_code: "",
  });
  const [loading, setLoading] = useState(false);

  const changeText = (key: string, value: string) => {
    const formDataCopy = { ...formData };
    // @ts-ignore
    formDataCopy[key] = value;
    setFormData(formDataCopy);
  };

  const send = () => {
    if (!formData.first_name) {
      toast(t("errors.name"), { type: "error" });
      return false;
    }

    if (!formData.email) {
      toast(t("errors.email"), { type: "error" });
      return false;
    }

    setLoading(true);
    API.contact
      .send(formData)
      .then((res) => {
        // @ts-ignore
        if (res?.response?.data?.status === false) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const [field, messages] of Object.entries(
            // @ts-ignore
            res?.response?.data?.messages
          )) {
            // @ts-ignore
            messages.forEach((message) => {
              toast(message, { type: "error" });
            });
          }
        } else {
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            message: "",
            country_code: countryCodes[0] || "",
          });
          toast(t("sentSuccessfully"), { type: "success" });
          navigate(currentLangUrl() + "/success");
        }

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setFormData((state) => ({ ...state, country_code: countryCodes[0] }));
  }, [countryCodes]);

  return (
    <ContactFormContainer>
      <ToastContainer />
      <h2 className="m-0">{t("sendMessage")}</h2>
      <div className="display-flex flex-column gap-200 mt-300 mb-300">
        <Input
          onChange={(e) => changeText("first_name", e.target.value)}
          value={formData.first_name}
          transparent
          placeholder={t("name")}
        />
        <Input
          onChange={(e) => changeText("last_name", e.target.value)}
          value={formData.last_name}
          transparent
          placeholder={t("lastname")}
        />

        <Input
          onChange={(e) => changeText("email", e.target.value)}
          value={formData.email}
          transparent
          placeholder={t("emailShort")}
        />

        <Input
          onChange={(e) => changeText("phone", e.target.value)}
          value={formData.phone}
          transparent
          placeholder={t("phone")}
          type="number"
          mobile
          codes={countryCodes.map((i) => ({ value: i, label: i }))}
          handleDropdownSelect={(value) => changeText("country_code", value)}
        />

        <Input
          onChange={(e) => changeText("message", e.target.value)}
          value={formData.message}
          transparent
          placeholder={t("message")}
          type="textarea"
        />
      </div>
      <Button onClick={() => send()} size="lg" disabled={loading}>
        {t("send")}
      </Button>
    </ContactFormContainer>
  );
};

export default ContactForm;
