import styled from "styled-components";

export const NotFoundContainer = styled.div`
    display: flex;
    min-height: 400px;
    height: 50dvh;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2,
    h1 {
        margin: 0 0 1.5rem 0;
        color: ${(props) => props.theme.colorTextPrimary};
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
    }

    h2 {
        margin: 0;
        font-size: 40px;
        font-weight: 700;
    }
`