import styled from 'styled-components'
import LoaderGif from '../../assets/icons/loader.svg'

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = () => {
  return (
    <LoaderContainer>
      <img src={LoaderGif} alt="loader" />
    </LoaderContainer>
  )
}

export default Loader
