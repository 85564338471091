import { globalStyles } from "consts/spaces";
import styled from "styled-components";
import Blockquote from "../../assets/icons/blockquote.svg";

export const BlogPageContainer = styled.div`
  margin: 0 auto;
`;

export const BlogList = styled.div`
  display: flex;
  flex-wrap: wrap;

  .col-3,
  .col-lg-4,
  .col-xs-12,
  .col-sm-6 {
    overflow: hidden;
    word-wrap: break-word;
  }
`;

export const SingleBlogContainer = styled.div`
  max-width: 1300px;
  color: ${(props) => props.theme.colorTextPrimary};

  img {
    width: 100%;
    max-height: 496px;
    object-fit: cover;
  }

  &.offers img {
    object-fit: contain;
  }

  h1 {
    margin: 0 0 32px 0;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      font-size: ${globalStyles.fonts.h3};
      margin: ${globalStyles.spaces[100]} 0;
    }
  }

  & > .row {
    @media (max-width: ${globalStyles.breackPoints.md}) {
      flex-direction: column-reverse;
      gap: ${globalStyles.spaces[100]};
      margin-bottom: ${globalStyles.spaces[200]};

      img {
        height: auto;
      }
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: ${globalStyles.spaces[400]} 0;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      margin: ${globalStyles.spaces[200]} 0;
    }

    &.date {
      margin: 0;
      color: ${(props) => props.theme.colorTextSubtlest};
    }

    &.slide-desc {
      margin: ${globalStyles.spaces[200]} 0 0 0;
    }
  }

  h2 {
    margin: ${globalStyles.spaces[400]} 0;
    font-style: normal;
    font-weight: 700;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      font-size: ${globalStyles.fonts.h4};
      margin: ${globalStyles.spaces[200]} 0;
    }
  }

  blockquote {
    font-size: ${globalStyles.fonts.h4};
    background-image: url(${Blockquote});
    padding-top: 33px;
    background-repeat: no-repeat;
    background-position: ${globalStyles.spaces[300]} 0;
    margin: ${globalStyles.spaces[400]} 0;
    padding-left: ${globalStyles.spaces[300]};
    border-left: 4px solid
      ${(props) => props.theme.colorBorderNeutralBolder_hovered};
  }

  .float-left {
    // display: flex;

    img {
      max-width: 220px;
      margin-right: ${globalStyles.spaces[300]};
      margin-bottom: ${globalStyles.spaces[300]};
      float: left;
    }

    p {
      margin: ${globalStyles.spaces[300]} 0;
    }

    &:after {
      content: "";
      clear: both;
      display: block;
    }
  }

  .float-right {
    // display: flex;
    // justify-content: space-between;

    img {
      float: right;
      max-width: 220px;
      margin-left: ${globalStyles.spaces[300]};
      margin-bottom: ${globalStyles.spaces[300]};
    }

    p {
      margin: ${globalStyles.spaces[300]} 0;
    }

    &:after {
      content: "";
      clear: both;
      display: block;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .mb-t-400 {
      margin-top: ${globalStyles.spaces[200]};
    }
  }
`;

export const SimilarNews = styled.div``;
