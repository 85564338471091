import { t } from "i18next";
import { Button } from "components/styled/Button";
import { SuccessContainer } from "./styled";
import { ReactComponent as SuccessIcon } from "assets/icons/success.svg";

function SuccessPage() {
  localStorage.setItem("page-category", t("successSend"));
  localStorage.removeItem("page-title");

  return (
    <SuccessContainer>
      <div className="container">
        <SuccessIcon />

        <h2>{t("successSend")}</h2>
        <p>{t("successText")}</p>

        <Button className="mt-400" variant="base" size="xl" link="/">
          {t("returnHome")}
        </Button>
      </div>
    </SuccessContainer>
  );
}

export default SuccessPage;
