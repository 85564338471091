import { CheckboxContainer, CheckboxItem } from "./styled";
import { ReactComponent as Check } from "assets/icons/checkIcon.svg";

type Props = {
  label: string;
  checked: boolean;
  onCheck: () => void;
};

function Checkbox({ label, checked, onCheck }: Props) {
  return (
    <CheckboxContainer onClick={onCheck}>
      <CheckboxItem active={checked}>{checked && <Check />}</CheckboxItem>

      {label}
    </CheckboxContainer>
  );
}

export default Checkbox;
