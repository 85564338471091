import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "assets/images/logo.svg";
import CloseIcon from "assets/icons/close.svg";
import searchIcon from "assets/icons/searchIcon.svg";
import { ReactComponent as Phone } from "assets/icons/phone.svg";
import { ReactComponent as SearchIcon } from "assets/icons/searchIcon.svg";
import Input from "components/styled/Input";
import { Button } from "components/styled/Button";
import MobileMenu from "./mobileMenu";
import { HeaderSearchContainer } from "./styled";
import { ICategories } from "types/project";
import { currentLangUrl } from "helpers";

interface IProps {
  dark: boolean;
  categories: ICategories[];
}

const HeaderSearch: FC<IProps> = ({ dark, categories }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    const handleBodyClass = () => {
      if (open) {
        document.body.classList.add("fixed");
      } else {
        document.body.classList.remove("fixed");
      }
    };

    handleBodyClass();
    return () => {
      document.body.classList.remove("fixed");
    };
  }, [open]);

  const onSubmit = () => {
    if (search) {
      navigate(`/search?search=${search}`);
      setSearch("");
      setOpen(false);
      setShowSearch(false);
    }
  };

  return (
    <HeaderSearchContainer className="pt-150 pb-150">
      <Link className="logo" to={currentLangUrl()}>
        <img src={Logo} alt="logo" />
      </Link>

      <div className="web-search">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            borderBottom
            placeholder={t("search")}
            icon={searchIcon}
          />
        </form>
      </div>

      <div className="display-flex gap-150 align-items-center hide-tablet">
        <Phone />

        <a className="f-p16 color-default phone" href="tel:+0322994455">
          +032 2 99 44 55
        </a>

        <a href="https://www.jcblivelink.com/" target="_blank" rel="noreferrer">
          <Button>{t("logIn")}</Button>
        </a>
      </div>

      <div className="mobile-search">
        <div className="search">
          <div className={`search-container ${showSearch ? "active" : ""}`}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                borderBottom
                placeholder={t("search")}
              />
            </form>
            <span onClick={() => setShowSearch(false)}>
              <img
                style={{
                  width: "24px",
                  position: "relative",
                  // top: "6px",
                }}
                alt="JCB"
                src={CloseIcon}
              />
            </span>
          </div>

          <SearchIcon onClick={() => setShowSearch(true)} />
        </div>

        <div className="menu-icon" onClick={() => setOpen(!open)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12ZM3.75 6.75H20.25C20.4489 6.75 20.6397 6.67098 20.7803 6.53033C20.921 6.38968 21 6.19891 21 6C21 5.80109 20.921 5.61032 20.7803 5.46967C20.6397 5.32902 20.4489 5.25 20.25 5.25H3.75C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6C3 6.19891 3.07902 6.38968 3.21967 6.53033C3.36032 6.67098 3.55109 6.75 3.75 6.75ZM20.25 17.25H3.75C3.55109 17.25 3.36032 17.329 3.21967 17.4697C3.07902 17.6103 3 17.8011 3 18C3 18.1989 3.07902 18.3897 3.21967 18.5303C3.36032 18.671 3.55109 18.75 3.75 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18C21 17.8011 20.921 17.6103 20.7803 17.4697C20.6397 17.329 20.4489 17.25 20.25 17.25Z"
              fill="#2C3E5D"
            />
          </svg>
        </div>
      </div>

      <MobileMenu
        close={() => setOpen(false)}
        open={open}
        categories={categories}
      />
    </HeaderSearchContainer>
  );
};
export default HeaderSearch;
