import { FC } from "react";
import { useLocation } from "react-router-dom";
import HeaderTop from "components/parts/header/top";
import { HeaderContainer } from "components/parts/header/styled";
import HeaderSearch from "components/parts/header/search";
import HeaderMenu from "components/parts/header/menu";
import { ICategories } from "types/project";

interface IProps {
  toggleTheme: () => void;
  lang?: string;
  dark: boolean;
  setLang?: (lang: string) => void;
  categories: ICategories[];
}

const Header: FC<IProps> = ({ toggleTheme, setLang, lang, categories }) => {
  const location = useLocation();
  const home = location.pathname === "/" || location.pathname === "/en";

  return (
    <HeaderContainer className={home ? "" : `mb-1000`} lang={lang}>
      <HeaderTop dark={true} setLang={setLang} />

      <div className="container">
        <HeaderSearch dark={true} categories={categories} />
      </div>

      <HeaderMenu
        path={location.pathname}
        dark={true}
        onClick={toggleTheme}
        categories={categories}
      />
    </HeaderContainer>
  );
};

export default Header;
