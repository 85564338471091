import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API } from "services/API/API";
import Logo from "assets/images/logo.svg";
import Fb from "assets/icons/fb.svg";
import linkedin from "assets/icons/linkedin.svg";
// import twitter from "assets/icons/twitter.svg";
import youtube from "assets/icons/youtube.svg";
import instagram from "assets/icons/instagram.svg";
import {
  FooterBottomMenu,
  FooterContainer,
  FooterCopyRight,
  FooterDescription,
  FooterMenu,
  FooterMenuLink,
} from "components/styled/Footer";
import { ICategories } from "types/project";
import { currentLangUrl } from "helpers";

const Footer = ({ categories }: { categories: ICategories[] }) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const [data, setData] = useState({
    address: "",
    phone_1: "",
    twitter_link: "",
    youtube_link: "",
    facebook_link: "",
    linkedin_link: "",
    instagram_link: "",
    email_1: "",
  });
  const [text, setText] = useState(``);
  const [readMore, setReadMore] = useState(false);
  const [staticPages, setStaticPages] = useState<
    { id: number; title: string; slug: string }[]
  >([]);

  useEffect(() => {
    API.contact.getContactData().then((res) => {
      localStorage.setItem('jcb_text', res.data?.footer_text.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 300));
      setText(res.data?.footer_text || "");
      setData(res.data);
    });

    API.about.getStaticPages().then((res) => {
      setStaticPages(res.data);
    });
  }, [lang]);

  return (
    <FooterContainer className="pt-500 mt-1000">
      <div className="container">
        {!!text && (
          <FooterDescription className="mt-500 mb-500 f-p14">
            {readMore ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: text.slice(0, 300),
                }}
              />
            )}
            {text.length > 300 && (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setReadMore(!readMore);
                }}
              >
                {" "}
                {!readMore ? t("...seeMore") : t("close")}
              </a>
            )}
          </FooterDescription>
        )}

        <FooterMenu className="display-flex pt-500 pb-500 row">
          <FooterMenuLink className="col-4 col-xs-12 col-sm-12 col-md-12 mr-auto">
            <Link to={"/"}>
              <img alt="JCB log" src={Logo} className="logo" />
            </Link>
            <p
              dangerouslySetInnerHTML={{
                __html: t("footerDescription"),
              }}
            />
            <div className="display-flex gap-300 mt-100 socials">
              {/* {data?.twitter_link && (
                <a target="_blank" href={data?.twitter_link} rel="noreferrer">
                  <img src={twitter} alt="JCB" />
                </a>
              )} */}
              {data?.linkedin_link && (
                <a target="_blank" href={data?.linkedin_link} rel="noreferrer">
                  <img src={linkedin} alt="JCB" />
                </a>
              )}
              {data?.youtube_link && (
                <a target="_blank" href={data?.youtube_link} rel="noreferrer">
                  <img src={youtube} alt="JCB" />
                </a>
              )}
              {data?.facebook_link && (
                <a target="_blank" href={data?.facebook_link} rel="noreferrer">
                  <img src={Fb} alt="JCB" />
                </a>
              )}

              {data?.instagram_link && (
                <a target="_blank" href={data.instagram_link} rel="noreferrer">
                  <img src={instagram} alt="JCB" />
                </a>
              )}
            </div>
          </FooterMenuLink>

          {!!categories?.length && (
            <FooterMenuLink className="col-3 col-xs-6 col-sm-6 col-md-4">
              <h4>{t("footerProducts")}</h4>

              {categories.map((item) => (
                <Link
                  key={item.id}
                  onClick={() => window.scrollTo(0, 0)}
                  to={`${currentLangUrl()}/products/${item.slug}`}
                >
                  {item.title}
                </Link>
              ))}
            </FooterMenuLink>
          )}

          <FooterMenuLink className="col-2 col-xs-6 col-sm-6 col-md-4">
            <h4>{t("aboutUs")}</h4>
            <Link to={`${currentLangUrl()}/offers`}>{t("offers")}</Link>
            <Link to={`${currentLangUrl()}/vlog`}>{t("vlog")}</Link>
            <Link to={`${currentLangUrl()}/blog`}>{t("blog")}</Link>
          </FooterMenuLink>

          <FooterMenuLink className="col-2 col-xs-6 col-sm-6 col-md-4">
            <h4>{t("contact")}</h4>
            <a href={`tel:${data?.phone_1}`}>{data?.phone_1}</a>
            <a href={`mailt:${data?.email_1}`}>{data?.email_1}</a>
            <span>{data?.address}</span>
          </FooterMenuLink>
        </FooterMenu>

        {!!staticPages.length && (
          <FooterBottomMenu className="pt-500 pb-500 gap-300 display-flex justify-center">
            {staticPages.map((item) => (
              <Link
                to={`${currentLangUrl()}/static/${item.slug}`}
                key={item.id}
              >
                {item.title}
              </Link>
            ))}
          </FooterBottomMenu>
        )}

        <FooterCopyRight className="pb-300 pt-300 text-center">
          {t("established")} 1869 · Copyright 2023
        </FooterCopyRight>
      </div>
    </FooterContainer>
  );
};

export default Footer;
