/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import DocumentMeta from "react-document-meta";
import ListItem, { IListItem } from "components/parts/news";
import { useEffect, useState } from "react";
import BlogFilter from "components/parts/news/filter";
import Pagination from "components/parts/pagination";
import { API } from "services/API/API";
import { ICategory } from "types/category";
import { useCanonicalUrl } from "services/hooks/canonical";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { t } from "i18next";
import { BlogList, BlogPageContainer } from "pages/Blog/styled";
import VideoSlider from "components/parts/videoSlider";

const Vlog = () => {
  const { i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const [cat, setCurrentCat] = useState("");
  const [perPage, setPerPage] = useState(9);
  const [totalPage, setTotalPage] = useState(9);
  const url = useCanonicalUrl();
  const meta = {
    title: `${t("vlog")} | ${ProjectTitle}`,
    description: t("vlog"),
    canonical: url,
  };
  const [category, setCategory] = useState<ICategory[]>([]);
  const [vlogs, setVlogs] = useState<IListItem[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [openSlider, setOpenSlider] = useState(false);

  const getCategories = () => {
    API.vlogs.getCategories().then((res) => {
      setCategory(res.data);
    });
  };

  const fetchData = (cat: string, page: number) => {
    API.vlogs.getVlogs(page, cat).then((res) => {
      setPerPage(res?.pagination?.per_page);
      setTotalPage(res?.pagination?.total);
      setVlogs(
        res?.data.map((item: any) => {
          return {
            ...item,
            description: "",
            url: item.slug,
            video:
              item.video_source === "youtube"
                ? item.youtube_url
                : item?.file?.url,
            img: item?.media?.url,
            youtubeUrl: item.video_source === "youtube" ? item.youtube_url : "",
            videoUrl:
              item.video_source === "youtube"
                ? item.youtube_url
                : item?.file?.url,
          };
        })
      );
    });
  };

  useEffect(() => {
    getCategories();
  }, [i18n.language]);

  useEffect(() => {
    fetchData(cat, page);
  }, [i18n.language]);

  const onPageChange = (e: number) => {
    const element = document.getElementById("page-container");
    element?.scrollIntoView({ behavior: "smooth" });
    setPage(e);
    fetchData(cat, e);
  };

  return (
    <DocumentMeta {...meta}>
      <BlogPageContainer id="page-container">
        <BlogFilter
          pageTitle="vlog"
          onActive={(e) => {
            setCurrentCat(e);
            setPage(1);
            fetchData(e, 1);
          }}
          category={category}
        />

        <VideoSlider
          imgs={vlogs.map((item) => (item.videoUrl ? item.videoUrl : item.img))}
          open={openSlider}
          index={index}
          onClose={() => setOpenSlider(false)}
        />

        <BlogList className="row container">
          {vlogs.map((item, index) => (
            <div
              className="col-3 col-lg-4 col-xs-12 col-sm-6"
              key={`vlog-${index}`}
            >
              <ListItem
                blogMax
                maxDescription
                withoutspace
                {...item}
                onOpen={() => {
                  setOpenSlider(true);
                  setIndex(index);
                }}
              />
            </div>
          ))}
        </BlogList>

        {totalPage > perPage && (
          <div className="container">
            <Pagination
              onChange={(e) => onPageChange(e)}
              page={page}
              perPage={perPage}
              totalPage={totalPage}
              className="container"
            />
          </div>
        )}
      </BlogPageContainer>
    </DocumentMeta>
  );
};

export default Vlog;
