/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { changeLanguage } from "locales/i18n";
import { langs } from "locales/languages";
import { HeaderTopContainer } from "./styled";
import { useLocation, useNavigate } from "react-router-dom";
// import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
  setLang?: (lang: string) => void;
  dark: boolean;
}

const HeaderTop: FC<IProps> = ({ setLang, dark }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLang, setCurrentLang] = useState<string>("ka");

  const changeLang = (lang: string) => {
    localStorage.setItem("JCB-lang", lang);
    setCurrentLang(lang);
    changeLanguage(lang);
    if (setLang) {
      setLang(lang);
    }
  };

  useEffect(() => {
    const storageLang = localStorage.getItem("JCB-lang");
    if (storageLang) {
      changeLang(storageLang);
    }
  }, []);

  const changeLanguageFunc = (ln: string) => {
    const currentUlr = location.pathname;

    if (ln === "ka") {
      if (currentUlr === "/en") {
        navigate("/");
      } else {
        navigate(currentUlr.replace("en/", "") + location?.search);
      }
    } else {
      if (currentUlr === "/") {
        navigate("/en");
      } else {
        if (!currentUlr.includes("en")) {
          navigate("en" + currentUlr + location?.search);
        }
      }
    }
  };

  useEffect(() => {
    const currentUlr = location.pathname;
    if (currentUlr.includes("en")) {
      changeLang("en");
    } else {
      changeLang("ka");
    }
  }, []);

  return (
    <HeaderTopContainer>
      <div className="container">
        <div className="display-flex justify-flex-end">
          {langs.map((item) => (
            <a
              key={item.key}
              className={`${currentLang === item.key ? "active" : ""} ${
                dark ? "dark" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                changeLang(item.key);
                changeLanguageFunc(item.key);
              }}
              href="."
            >
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </HeaderTopContainer>
  );
};

export default HeaderTop;
