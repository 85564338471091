import axiosInstance from "..";

const getAbout = (slug?: string) => {
  if (slug) {
    return axiosInstance.get(`staticpage/show/${slug}`).then((res) => {
      return res.data?.data;
    });
  } else {
    return axiosInstance.get("aboutpage").then((res) => {
      return res.data;
    });
  }
};

const getStaticPage = (slug?: string) => {
  return axiosInstance.get(`staticpage/show/${slug}`).then((res) => {
    return res.data;
  });
};

const getStaticPages = () => {
  return axiosInstance.get("/staticpage").then((res) => {
    return res.data;
  });
};

export const about = {
  getAbout,
  getStaticPage,
  getStaticPages,
};
