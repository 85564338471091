import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const HeaderTopContainer = styled.div`
  display: flex;
  justify-content: end;
  height: 40px;
  align-items: center;

  a {
    padding: ${globalStyles.spaces[0]} ${globalStyles.spaces[150]};
    color: ${(props) => props.theme.colorTextInverse};
    opacity: 0.6;

    &::after {
      content: "|";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: ${(props) => props.theme.colorTextDisabled};
      padding-left: ${globalStyles.spaces[150]};
    }

    &:last-child::after {
      display: none;
    }

    &.active,
    &:hover {
      opacity: 1;
    }

    &.dark {
      color: ${(props) => props.theme.colorTextDisabled};

      &.active,
      &:hover {
        color: ${(props) => props.theme.colorLinkDefault};
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    display: none;
  }
`;

export const HeaderContainer = styled.header`
  background-color: transparent;
  border-bottom: 1px solid rgba(9, 30, 66, 0.14);

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    // margin-bottom: ${globalStyles.spaces[400]};
    padding: 0;

    &.pb-0 {
      margin-bottom: 0;
    }

    .search {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div .burger-menu {
      border: 12px solid transparent;
      width: 48px;
      height: 44px;
      margin-top: 4px;
    }

    div .search-container {
      top: 0px;
    }
  }

  &.fixed-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.16) 0%,
      rgba(0, 0, 0, 0) 184.94%
    );
  }

  &.dark:not(.fixed-header) {
    background-color: ${(props) => props.theme.colorBackgroundNeutralBoldest};
  }
`;

export const HeaderSearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  a.logo {
    height: 56px;

    img {
      height: 56px;
      border-radius: 8px;
    }
  }

  & > img {
    height: 69px;
  }

  .search {
    position: relative;

    svg {
      cursor: pointer;
    }

    &-container {
      position: absolute;
      width: 0px;
      overflow: hidden;
      top: -12px;
      right: 0;
      display: flex;

      & > span {
        display: flex;
        height: 54px;
        background: #fff;
        font-size: 22px;
        font-weight: 800;
        font-family: system-ui;
        // margin-top: 10px;
        cursor: pointer;
        padding: 0 5px;
      }

      &.active {
        width: 220px;
      }
    }

    @media (min-width: ${globalStyles.breackPoints.tablet}) {
      display: none;
    }
  }

  .web-search {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    flex: 1;
    width: 400px;

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      display: none;
    }

    @media (max-width: ${globalStyles.breackPoints.md}) {
      input {
        border: none;
      }
    }
  }

  .mobile-search {
    display: flex;
    align-items: center;
    gap: 20px;

    .menu-icon {
      display: none;
      align-items: center;
      cursor: pointer;

      @media (max-width: ${globalStyles.breackPoints.md}) {
        display: flex;
      }
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      .search {
        display: flex;
      }
    }

    @media (min-width: ${globalStyles.breackPoints.md}) {
      display: none;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    height: auto;
    align-items: top;
  }

  .burger-menu {
    cursor: pointer;
    position: relative;
    // z-index: 7;
    width: 24px;
    height: 19px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #44546f;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    &.dark span {
      background: #fff;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2) {
      top: 8px;
    }

    span:nth-child(3) {
      top: 16px;
    }

    &.open span:nth-child(1) {
      top: 18px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
      top: 9px;
      background: #44546f;
    }

    &.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
      background: #44546f;
    }

    &.open span:nth-child(3) {
      top: 9px;
      background: #44546f;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    a.logo {
      height: 40px;

      img {
        height: 40px;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    a.logo {
      height: 32px;

      img {
        height: 32px;
      }
    }
  }

  .phone:hover {
    color: ${(props) => props.theme.colorLinkDefault};
    text-decoration: underline;
  }
`;

export const HeaderSearchInputContainer = styled.div`
  width: 30%;
`;

interface IProps {
  open: boolean;
}

export const MobileMenuContainer = styled.div<IProps>`
  position: fixed;
  top: 0;
  right: ${(props) => (props.open ? "0px" : "-100%")};
  width: 100dvw;
  height: 100dvh;
  z-index: 6;
  background-color: #fff;
  overflow: scroll;

  .header {
    border-bottom: 1px solid rgba(9, 30, 66, 0.14);
  }

  .content {
    height: calc(100dvh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
  }

  .w-100 {
    width: 100%;
    cursor: pointer;
  }

  div.footer-menu {
    border-top: 1px solid ${(props) => props.theme.colorBorderDisabled};
  }

  div.header-menu {
    // border-bottom: 1px solid ${(props) => props.theme.colorBorderDisabled};

    h2 {
      margin: 0;
    }
  }

  .header-menu-container {
    button {
      background: transparent;
      text-align: left;
      width: 100%;

      &.active {
        font-weight: bold;
      }
    }
  }

  h2 {
    color: ${(props) => props.theme.colorTextPrimary};
  }

  .lang a {
    color: ${(props) => props.theme.colorTextDisabled};
    opacity: 0.6;

    &.active,
    &:hover {
      color: ${(props) => props.theme.colorLinkDefault};
      opacity: 1;
    }
  }

  svg,
  img {
    cursor: pointer;
  }

  @media (min-width: ${globalStyles.breackPoints.md}) {
    display: none;
  }
`;

export const MenuDropdown = styled.div`
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  background: #fff;
  box-shadow: 0px 8px 16px -8px rgba(23, 43, 77, 0.2);
  padding: ${globalStyles.spaces[150]};
  overflow: hidden;
  z-index: 5;
  display: none;
  max-height: 300px;
  overflow: auto;

  li {
    min-width: 280px;
    display: block;
    padding: ${globalStyles.spaces[100]} ${globalStyles.spaces[200]};
    text-align: left;

    &:hover {
      background: #f1f2f4;

      a {
        color: ${(props) => props.theme.colorTextPrimary};
      }
    }

    a {
      display: block;
      line-height: 24px;
      color: ${(props) => props.theme.colorTextPrimary};
    }

    &.active {
      font-weight: bold;
    }
  }
`;

export const HeaderMenuContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    gap: ${globalStyles.spaces[100]};

    &:hover {
      background-color: ${(props) =>
        props.theme.colorBackgroundSurfaceSurfaceHovered};
      color: ${(props) => props.theme.colorTextPrimary};
    }
  }

  button.position-relative {
    border-radius: 0;

    svg {
      position: relative;
      top: 3px;
      left: 8px;

      path {
        fill: #fff;
      }
    }

    &:hover {
      background: ${(props) =>
        props.theme.colorBackgroundSurfaceSurfaceReversedHovered};
      color: ${(props) => props.theme.colorTextPrimary};

      svg {
        transform: rotate(-180deg);

        path {
          fill: #44546f;
        }
      }

      div {
        opacity: 1;
        pointer-events: all;
        max-height: 300px;
      }
    }
  }

  .dropdown-container {
    position: relative;

    &.hover {
      button svg {
        transform: rotate(-180deg);
      }

      .menu {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
