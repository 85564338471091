import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.colorTextPrimary};
  cursor: pointer;
`;

export const CheckboxItem = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  min-width: 18px;
  border: 2px solid
    ${(props) =>
      props.active
        ? props.theme.colorBackgroundAccentYellowBolder
        : props.theme.colorBorderNeutralBolder};
  background: ${(props) =>
    props.active
      ? props.theme.colorBackgroundAccentYellowBolder
      : "transparent"};
`;
