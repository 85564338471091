import { FC } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import { globalStyles } from "consts/spaces";
import { IHomeSlider } from "types/home";
import { Slide } from "components/styled/Slide";
import CarretRightSlider from "assets/icons/CaretRightSlider.svg";

import { removeHtmlTags } from "helpers";
import { useTranslation } from "react-i18next";
import { Button } from "components/styled/Button";

const CarouselContainer = styled.div`
  .control-arrow.control-prev,
  .control-arrow.control-next {
    right: 3%;
    right: 3%;
    bottom: 45%;
    top: auto;
    z-index: 5;
    width: 3rem;
    height: 3rem;
    background: #fff !important;
    text-align: center;

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      display: none;
    }

    &:before {
      display: none;
    }

    img {
      width: 30px;
      margin-top: 4px;
    }

    &:hover {
      background: transparent;
    }
  }

  .control-dots {
    & > li.dot {
      height: 8px;
      width: 8px;
      opacity: 1;
      background-color: ${(props) => props.theme.colorShapeInverse};
      border-radius: 0;
      box-shadow: none;
      margin: 0 4px;

      &.selected {
        width: 24px;
        background-color: ${(props) => props.theme.colorShapeInverse};
      }
    }

    // @media (min-width: ${globalStyles.breackPoints.tablet}) {
    //   display: none;
    // }
  }

  .control-arrow.control-prev {
    left: 3%;

    img {
      transform: rotate(-180deg);
    }
  }
`;

interface IProps {
  slider: IHomeSlider[];
}

const HomeSlider: FC<IProps> = ({ slider }) => {
  const { t } = useTranslation();

  return (
    <CarouselContainer>
      {!!slider?.length && (
        <Carousel
          swipeable
          emulateTouch
          showArrows
          infiniteLoop={false}
          showStatus={false}
          stopOnHover={false}
          showIndicators={true}
          showThumbs={false}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                className={`${
                  hasPrev ? "position-absolute" : "display-none"
                } control-arrow control-prev`}
                onClick={clickHandler}
              >
                <img loading="lazy" alt="JCB" src={CarretRightSlider} />
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <div
                className={`${
                  hasNext ? "position-absolute" : "display-none"
                } control-arrow control-next`}
                onClick={clickHandler}
              >
                <img loading="lazy" alt="JCB" src={CarretRightSlider} />
              </div>
            );
          }}
        >
          {slider.map((item) => (
            <Slide key={item.id}>
              <img loading="lazy" src={item?.media?.url} alt={item?.title} />
              <div>
                <div className="container">
                  <h2>{item?.title}</h2>
                  <p className="hide-mobile">
                    {removeHtmlTags(item?.description)}
                  </p>
                  <div>
                    {item?.link && (
                      <>
                        {!item.link_target ? (
                          <a
                            className="link"
                            href={item?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("more")}
                          </a>
                        ) : (
                          <Button link={item?.link} size="lg">
                            {t("more")}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Slide>
          ))}
        </Carousel>
      )}
    </CarouselContainer>
  );
};

export default HomeSlider;
