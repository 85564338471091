import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Global } from '../../consts/global'
import i18n from 'locales/i18n'

const baseURL = Global.apiUrl

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
})

axiosInstance.interceptors.request.use(
  // @ts-ignore
  (config: AxiosRequestConfig) => {
    if (!config.headers) {
      //@ts-ignore
      config.headers = {};
    }
    
    config.headers['Accept-Language'] = i18n.language || 'ka';
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error) => {
    if (error.response) {
      console.error('Response Error:', error.response.data)
      console.error('Status:', error.response.status)
      console.error('Headers:', error.response.headers)
    } else if (error.request) {
      console.error('Request Error:', error.request)
    } else {
      console.error('Error:', error.message)
    }

    return error
  },
)

export default axiosInstance
