import { globalStyles } from 'consts/spaces'
import styled from 'styled-components'

export const Title = styled.h2`
  font-size: ${globalStyles.fonts.h1};
  line-height: ${globalStyles.fonts.h1};
  color: ${(props) => props.theme.colorTextPrimary};
  margin: 0;
  font-style: normal;
    font-weight: 700;

  @media (max-width: ${globalStyles.breackPoints.md}) {
    font-size: ${globalStyles.fonts.h3};
  }
`

export const TitleH1 = styled.h1`
  font-size: ${globalStyles.fonts.h1};
  line-height: ${globalStyles.fonts.h1};
  color: ${(props) => props.theme.colorTextPrimary};
  margin: 0;
  font-style: normal;
    font-weight: 700;

  @media (max-width: ${globalStyles.breackPoints.md}) {
    font-size: ${globalStyles.fonts.h3};
  }
`
