import axiosInstance from '..'

const getHomeData = () => {
  return axiosInstance.get('homepage').then((res) => {
    return res.data
  })
}

export const home = {
  getHomeData: getHomeData,
}
