import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const VideoSliderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }

  & > img {
    position: absolute;
    right: 48px;
    top: 48px;
    width: 64px;
    height: 64px;
    cursor: pointer;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      width: 32px;
    }
  }

  .img-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70%;
    height: 60%;
    transform: translate(-50%, -50%);

    @media (max-width: ${globalStyles.breackPoints.md}) {
      width: 80%;
      height: 40%;
    }

    & > div,
    iframe,
    video {
      height: 100%;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .arrow {
    position: absolute;
    left: 8%;
    top: 50%;
    margin-top: -48px;
    width: 84%;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      width: 96%;
      left: 2%;
    }

    img {
      cursor: pointer;
      width: 96px;
      height: 96px;

      @media (max-width: ${globalStyles.breackPoints.md}) {
        width: 32px;
      }
    }

    .right {
      float: right;
    }

    .left {
      transform: rotate(180deg);
    }
  }
`;
