/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { ProjectTitle } from "consts/global";
import { removeHtmlTags } from "helpers";
import { API } from "services/API/API";
import { ICategory } from "types/category";
import { useCanonicalUrl } from "services/hooks/canonical";
import ListItem, { IListItem } from "components/parts/news";
import BlogFilter from "components/parts/news/filter";
import Pagination from "components/parts/pagination";
import { BlogList, BlogPageContainer } from "./styled";

const Blog = () => {
  const { i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const [cat, setCurrentCat] = useState("");
  const [perPage, setPerPage] = useState(9);
  const [totalPage, setTotalPage] = useState(9);
  const url = useCanonicalUrl();
  const meta = {
    title: `${t("blog")} | ${ProjectTitle}`,
    description: t("blog"),
    canonical: url,
  };
  const [category, setCategory] = useState<ICategory[]>([]);
  const [newsData, setNewsData] = useState<IListItem[]>([]);

  const getCategories = () => {
    API.blogs.getCategories().then((res) => {
      setCategory(res.data);
    });
  };

  const fetchData = (cat: string, page: number) => {
    if (cat) {
      API.blogs.getBlogsByCategory(cat, page).then((res) => {
        setPerPage(res.blogsData?.pagination?.per_page);
        setTotalPage(res.blogsData?.pagination?.total);
        setNewsData(
          res?.blogsData.data.map((item: any) => {
            return {
              ...item,
              description: removeHtmlTags(item.short_description),
              url: item.slug,
              img: item["main-image"]?.url,
            };
          })
        );
      });
    } else {
      API.blogs.getBlogs(page).then((res) => {
        setPerPage(res.blogsData?.pagination?.per_page);
        setTotalPage(res.blogsData?.pagination?.total);
        setNewsData(
          res?.blogsData.data.map((item: any) => {
            return {
              ...item,
              description: removeHtmlTags(item.short_description),
              url: item.slug,
              img: item["main-image"]?.url,
            };
          })
        );
      });
    }
  };

  useEffect(() => {
    getCategories();
  }, [i18n.language]);

  useEffect(() => {
    fetchData(cat, page);
  }, [i18n.language]);

  const onPageChange = (e: number) => {
    const element = document.getElementById("page-container");
    element?.scrollIntoView({ behavior: "smooth" });
    setPage(e);
    fetchData(cat, e);
  };

  return (
    <DocumentMeta {...meta}>
      <BlogPageContainer id="page-container">
        <BlogFilter
          pageTitle="blog"
          onActive={(e) => {
            setCurrentCat(e);
            setPage(1);
            fetchData(e, 1);
          }}
          category={category}
        />
        <BlogList className="row container">
          {newsData.map((item, index) => (
            <div
              className="col-4 col-md-6 col-xs-12 col-sm-12"
              key={`blog-${index}`}
            >
              <ListItem
                blogMax
                maxDescription
                withoutspace
                {...item}
                url={"/blog/" + item.url}
              />
            </div>
          ))}
        </BlogList>
        {totalPage > perPage && (
          <div className="container">
            <Pagination
              onChange={(e) => onPageChange(e)}
              page={page}
              perPage={perPage}
              totalPage={totalPage}
              className="container"
            />
          </div>
        )}
      </BlogPageContainer>
    </DocumentMeta>
  );
};

export default Blog;
