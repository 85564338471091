import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const FilterCotainer = styled.div``;

export const FilterList = styled.div`
  transition: all 0.3s ease-in-out;

  .category,
  .sub-category {
    cursor: pointer;
    color: ${(props) => props.theme.colorLinkDefault};

    &.active {
      font-weight: bold;
    }
  }

  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .arrow-up {
    font-size: 14px;
    font-weight: 500;
    transform: rotate(180deg);
    margin-top: 4px;
  }

  .showAllModels {
    color: #000825;
    cursor: pointer;
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    padding-bottom: ${globalStyles.spaces[100]};

    .blog-cats {
      scrollbar-width: none;
      width: 100%;

      @media (max-width: ${globalStyles.breackPoints.md}) {
        overflow: scroll;

        & > div {
          display: block;
          min-width: 120%;
          min-width: auto;
          width: max-content;

          button {
            margin-right: ${globalStyles.spaces[200]};

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  button {
    white-space: nowrap;
    font-weight: 700;
    font-size: ${globalStyles.fonts.b20};
    color: ${(props) => props.theme.colorTextPrimary};
  }
`;

export const FilterCotainerSmall = styled.div`
  display: none;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colorBackgroundInputHovered};
  margin-bottom: 40px;

  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: 250px;
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    display: flex;
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 24px;

    .filter-button {
      min-width: 100%;
    }
  }
`;
