interface ILang {
  key: string;
  name: string;
}

export const langs: ILang[] = [
  {
    key: "ka",
    name: "ქართული",
  },
  {
    key: "en",
    name: "English",
  },
  // {
  //   key: "ru",
  //   name: "Русский",
  // },
];
