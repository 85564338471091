import { lightColors } from './colors/light'
import { darkColors } from './colors/dark'

// theme.ts
export const lightTheme = {
  ...lightColors,
  mainColor: '#000',
  buttonBg: '#000',
  standartTextColor: '#000',
}

export const darkTheme = {
  ...darkColors,
  mainColor: '#fff',
  buttonBg: '#fff',
  standartTextColor: '#fff',
}
