import { FC, memo } from "react";
import { Routes, Route } from "react-router-dom";

import Homepage from "pages/Homepage";
import Blog from "pages/Blog";
import Contact from "pages/Contact";
import Projects from "pages/Projects";
import Offers from "pages/Offers";
import SingleBlog from "pages/Blog/single";
import SingleProject from "pages/Projects/single";
import SingleOffer from "pages/Offers/single";
import About from "pages/about";
import Search from "pages/search";
import Vlog from "pages/Vlog";
import SuccessPage from "pages/Success";
import NotFound from "pages/404";
// import SingleVlog from "pages/Vlog/single";

interface IProps {
  lang?: string;
}

const Router: FC<IProps> = ({ lang }) => {
  return (
    <Routes data-lang={lang}>
      <Route path="/">
        <Route index element={<Homepage />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id" element={<SingleBlog />} />

        <Route path="vlog" element={<Vlog />} />

        <Route path="contact" element={<Contact />} />

        <Route path="products" element={<Projects />} />
        <Route path="products/:id" element={<Projects />} />
        <Route path="used" element={<Projects />} />
        <Route path="product/:id" element={<SingleProject />} />

        <Route path="offers" element={<Offers />} />
        <Route path="offers/:id" element={<SingleOffer />} />

        <Route path="static/:id" element={<SingleOffer />} />
        <Route path="about" element={<About />} />
        <Route path="search" element={<Search />} />
        <Route path="success" element={<SuccessPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="/en">
        <Route index element={<Homepage />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id" element={<SingleBlog />} />

        <Route path="vlog" element={<Vlog />} />

        <Route path="contact" element={<Contact />} />

        <Route path="products" element={<Projects />} />
        <Route path="products/:id" element={<Projects />} />
        <Route path="used" element={<Projects />} />
        <Route path="product/:id" element={<SingleProject />} />

        <Route path="offers" element={<Offers />} />
        <Route path="offers/:id" element={<SingleOffer />} />

        <Route path="static/:id" element={<SingleOffer />} />
        <Route path="about" element={<About />} />
        <Route path="search" element={<Search />} />
        <Route path="success" element={<SuccessPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default memo(Router);
