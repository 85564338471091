import styled from "styled-components";

export const SuccessContainer = styled.div`
  display: flex;
  min-height: 400px;
  height: 50dvh;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    margin: 0;
    margin-bottom: 0.5rem;
    color: ${(props) => props.theme.colorTextPrimary};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;

    @media (max-width: 1247px) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: 743px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  p {
    margin: 0;
    color: ${(props) => props.theme.colorTextPrimary};
    line-height: 24px;
  }
`;
