/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaginationContainer } from "./styled";

interface IProps {
  page: number;
  perPage: number;
  totalPage: number;
  className?: string;
  onChange: (active: number) => void;
}

const Pagination: FC<IProps> = ({ page, perPage, totalPage, onChange }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState<number>(page);
  const count = Math.ceil(totalPage / perPage);
  const pages = Array.from(Array(count), (_, i) => i + 1);

  return (
    <PaginationContainer>
      <a
        onClick={(e) => {
          e.preventDefault();
          onChange(active - 1);
          setActive(active - 1);
        }}
        className={active === 1 ? "invisible left" : "left"}
        href="/"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M12.9422 15.8078C13.0003 15.8659 13.0463 15.9348 13.0777 16.0107C13.1092 16.0865 13.1254 16.1679 13.1254 16.25C13.1254 16.3321 13.1092 16.4134 13.0777 16.4893C13.0463 16.5652 13.0003 16.6341 12.9422 16.6922C12.8841 16.7502 12.8152 16.7963 12.7393 16.8277C12.6634 16.8592 12.5821 16.8753 12.5 16.8753C12.4179 16.8753 12.3366 16.8592 12.2607 16.8277C12.1848 16.7963 12.1159 16.7502 12.0578 16.6922L5.80782 10.4422C5.74971 10.3841 5.70361 10.3152 5.67215 10.2393C5.6407 10.1634 5.62451 10.0821 5.62451 9.99998C5.62451 9.91785 5.6407 9.83652 5.67215 9.76064C5.70361 9.68477 5.74971 9.61584 5.80782 9.55779L12.0578 3.30779C12.1751 3.19052 12.3342 3.12463 12.5 3.12463C12.6659 3.12463 12.8249 3.19052 12.9422 3.30779C13.0595 3.42507 13.1254 3.58413 13.1254 3.74998C13.1254 3.91583 13.0595 4.07489 12.9422 4.19217L7.1336 9.99998L12.9422 15.8078Z"
            fill="#FCB026"
          />
        </svg>
        <span>{t("previous")}</span>
      </a>

      {pages.length > 10 ? (
        <>
          {pages.slice(0, 5).map((item) => (
            <a
              className={item === active ? "active" : ""}
              key={`page-${item}`}
              onClick={(e) => {
                e.preventDefault();
                onChange(item);
                setActive(item);
              }}
              href=""
            >
              {item}
            </a>
          ))}
          <a href="">...</a>
          <a
            className={pages.length === active ? "active" : ""}
            href=""
            onClick={(e) => {
              e.preventDefault();
              onChange(pages.length);
              setActive(pages.length);
            }}
          >
            {pages.length}
          </a>
        </>
      ) : (
        pages.map((item) => (
          <a
            className={item === active ? "active" : ""}
            key={`page-${item}`}
            onClick={(e) => {
              e.preventDefault();
              onChange(item);
              setActive(item);
            }}
            href=""
          >
            {item}
          </a>
        ))
      )}

      <a
        onClick={(e) => {
          e.preventDefault();
          onChange(active + 1);
          setActive(active + 1);
        }}
        className={active === count ? "invisible left" : "left"}
        href="/"
      >
        <span>{t("next")}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M14.1922 10.4422L7.94217 16.6922C7.8841 16.7502 7.81516 16.7963 7.73929 16.8277C7.66342 16.8592 7.5821 16.8753 7.49998 16.8753C7.41786 16.8753 7.33654 16.8592 7.26067 16.8277C7.1848 16.7963 7.11586 16.7502 7.05779 16.6922C6.99972 16.6341 6.95366 16.5652 6.92224 16.4893C6.89081 16.4134 6.87463 16.3321 6.87463 16.25C6.87463 16.1679 6.89081 16.0865 6.92224 16.0107C6.95366 15.9348 6.99972 15.8659 7.05779 15.8078L12.8664 9.99998L7.05779 4.19217C6.94052 4.07489 6.87463 3.91583 6.87463 3.74998C6.87463 3.58413 6.94052 3.42507 7.05779 3.30779C7.17507 3.19052 7.33413 3.12463 7.49998 3.12463C7.66583 3.12463 7.82489 3.19052 7.94217 3.30779L14.1922 9.55779C14.2503 9.61584 14.2964 9.68477 14.3278 9.76064C14.3593 9.83652 14.3755 9.91785 14.3755 9.99998C14.3755 10.0821 14.3593 10.1634 14.3278 10.2393C14.2964 10.3152 14.2503 10.3841 14.1922 10.4422Z"
            fill="#FCB026"
          />
        </svg>
      </a>
    </PaginationContainer>
  );
};

export default memo(Pagination);
