import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { globalStyles } from "consts/spaces";
import { IHomeProducts } from "types/home";
import { SubTitle } from "components/styled/SubTitle";
import { IItem } from "../list";
import useDeviceType, { currentLangUrl } from "helpers";

interface IProps {
  title?: string;
  hidemore?: boolean;
  scroll?: boolean;
  products?: IHomeProducts[];
}

const ModelList = styled.div`
  .single-model {
    min-height: 200px;
    position: relative;
    width: 100%;
    height: 560px;

    img {
      width: 100%;
      height: 560px;
      object-fit: cover;
    }

    &-info {
      position: absolute;
      left: 2rem;
      bottom: 2rem;
      color: #fff;
      z-index: 2;
      width: calc(100% - 4rem);

      h3 {
        margin: 0.25rem 0 0 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .single-model,
    .single-model img {
      height: 320px;
    }

    .single-model-info {
      bottom: 1.5rem;
      left: 1.5rem;
      width: calc(100% - 3rem);
    }
  }
`;

const Model = styled.div<{ url: string }>`
  width: 100%;
  min-height: 200px;
  height: 560px;
  object-fit: cover;
  border-radius: var(--Card-Radius-corner-050, 0px);
  background:
    linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.5) 100%),
    url("${(props) => props.url}") lightgray 50% / cover no-repeat;
`;

const HomeCars: FC<IProps> = ({ products }) => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const [models, setModels] = useState<IItem[]>([]);
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (imgRef.current) {
        setImgWidth(imgRef?.current?.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [imgRef?.current?.offsetWidth]);

  useEffect(() => {
    if (products && products.length > 0) {
      const arr: IItem[] = products.map((item) => {
        return {
          title: item.title,
          model: item.brand,
          price: item.price || 0,
          img: item?.media?.url,
          url: item.slug,
        };
      });

      setModels(arr);
    }
  }, [products]);

  return (
    <ModelList>
      <SubTitle center>{t("modelRange")}</SubTitle>
      <div className="row gap-300">
        {models.map((item) => (
          <div
            className="single-model col-3"
            key={item.title}
            style={deviceType !== "desktop" ? { height: imgWidth / 1.38 } : {}}
          >
            <Link to={currentLangUrl() + "/products/" + item.url}>
              <Model
                ref={imgRef}
                url={item.img}
                style={
                  deviceType !== "desktop" ? { height: imgWidth / 1.38 } : {}
                }
              />

              <div className="single-model-info">
                <span>{item.model}</span>
                <h3>{item.title}</h3>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </ModelList>
  );
};

export default HomeCars;
