import { globalStyles } from 'consts/spaces'
import styled from 'styled-components'

export const ContactFormContainer = styled.div`
  padding: ${globalStyles.spaces[300]} 0;
  max-width: 640px;
  margin: 0 auto;

  h2 {
    color: ${(props) => props.theme.colorTextPrimary};
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    .mobile-column {
      flex-direction: column;
    }
  }
`
