import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useDeviceType from "helpers";
import { IHomeProject } from "types/home";
import { SubTitle } from "components/styled/SubTitle";
import { DefaultSliderContainer, SliderContainer } from "pages/Homepage/styled";
import HomeSingleOffer, { SinleOffer } from "./homeOffer";
import DiscoverMore from "components/styled/DiscoverMore";
import { ReactComponent as ArrowLeft } from "assets/icons/CaretLeft.svg";

interface IProps {
  offers: IHomeProject[];
}

export const deviceBreakpoints = {
  mobile: 480,
  tablet: 744,
  md: 1280,
  lg: 1680,
};

const Offers: FC<IProps> = ({ offers }) => {
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const [screenSizes, setScreenSizes] = useState<number>(1);
  const [activeSlide, setActiveSlide] = useState(0);
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  const [slideIndex, setSlideIndex] = useState(0);
  const [lastSlide, setLastSlide] = useState(false);
  const [perSlide, setPerSlide] = useState(4);

  useEffect(() => {
    const updateWidth = () => {
      if (imgRef.current) {
        setImgWidth(imgRef?.current?.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [imgRef?.current?.offsetWidth]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (offers.length < 4) {
        setScreenSizes(4);
        setPerSlide(4);
      } else {
        if (
          screenWidth >= deviceBreakpoints.tablet &&
          screenWidth < deviceBreakpoints.md
        ) {
          setScreenSizes(2.5);
          setPerSlide(2);
        } else if (
          screenWidth >= deviceBreakpoints.md &&
          screenWidth < deviceBreakpoints.lg
        ) {
          setScreenSizes(3.5);
          setPerSlide(3);
        } else {
          setScreenSizes(4.5);
          setPerSlide(4);
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [offers.length]);

  const beforeChange = (_: number, next: number) => {
    setSlideIndex(Math.round(next));
  };

  function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
      <button className="slick-next" onClick={onClick} disabled={lastSlide}>
        <ArrowLeft />
      </button>
    );
  }

  function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <button
        className="slick-prev"
        onClick={onClick}
        disabled={+slideIndex === 0}
      >
        <ArrowLeft />
      </button>
    );
  }

  let settings = {
    dots: false,
    arrows: deviceType === "mobile" ? false : true,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: screenSizes,
    slidesToScroll: 1,
    initialSlide: 0,
    afterChange: (current: number) => {
      if (current.toString().includes(".")) {
        setActiveSlide(1);
      } else {
        setActiveSlide(0);
      }
    },
    beforeChange,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    if (+offers?.length - perSlide - +slideIndex) {
      setLastSlide(false);
    } else {
      setLastSlide(true);
    }
  }, [offers?.length, perSlide, slideIndex]);

  return (
    <>
      <div className="container">
        <SubTitle className="mb-300">{t("offers")}</SubTitle>
      </div>

      {deviceType === "mobile" ? (
        <div
          className="display-flex flex-column pl-250 pr-250 similar-mobile"
          style={{ gap: "24px" }}
        >
          {offers.slice(0, 5).map((item, index) => (
            <SinleOffer key={index}>
              <img
                ref={imgRef}
                src={item.img}
                alt={item.title}
                style={{ height: imgWidth / 1.33 }}
              />

              <h3>{item.title}</h3>
              <p>{item.short_description}</p>
            </SinleOffer>
          ))}
        </div>
      ) : (
        <>
          {offers.length < 4 ? (
            <div className="container">
              <DefaultSliderContainer className="without-slider">
                <div className="row">
                  {offers.map((item, index) => (
                    <div className="col-3 col-md-6 col-sm-12">
                      <div key={`room-${index}`}>
                        <HomeSingleOffer {...item} />
                      </div>
                    </div>
                  ))}
                </div>
              </DefaultSliderContainer>
            </div>
          ) : (
            <SliderContainer>
              <div className={`container activeSlide-${activeSlide}`}>
                <div className="slider-services">
                  <Slider {...settings}>
                    {offers.map((item, index) => (
                      <div key={`room-${index}`}>
                        <HomeSingleOffer {...item} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </SliderContainer>
          )}
        </>
      )}

      <div className="display-flex justify-center mt-600">
        <DiscoverMore url="/offers" />
      </div>
    </>
  );
};

export default Offers;
