import { FC } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import CarretRightSlider from "assets/icons/CaretRightSlider.svg";
import ZoomIcon from "assets/icons/zoom.svg";
import { globalStyles } from "consts/spaces";
import Fancybox from "../fancybox";

const CarouselContainer = styled.div`
  // width: 92%;
  margin: 0 auto;

  .single-slide {
    display: block;
    padding: 1rem;
    border: 1px solid rgba(9, 30, 66, 0.14);

    a {
      display: block;
    }

    img {
      max-height: 612px;
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      object-fit: contain;
      border-radius: 0;
      height: 568px;
    }

    .zoom {
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(9, 30, 66, 0.06);

      img {
        height: 24px;
        width: 24px;
      }
    }
  }

  .control-arrow.control-prev,
  .control-arrow.control-next {
    right: 0;
    bottom: 45%;
    top: auto;
    z-index: 5;
    width: 3rem;
    height: 3rem;
    background: rgba(9, 30, 66, 0.03) !important;
    text-align: center;

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      display: none;
    }

    &:before {
      display: none;
    }

    img {
      width: 30px;
      margin-top: 4px;
    }

    &:hover {
      background: transparent;
    }
  }

  .control-dots {
    display: none;
  }

  .control-arrow.control-prev {
    left: 0%;

    img {
      transform: rotate(-180deg);
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .control-arrow {
      display: none !important;
    }

    .single-slide {
      img {
        width: auto;
        height: 566px;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    .single-slide {
      img {
        width: auto;
        height: 412px;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    .single-slide {
      img {
        width: auto;
        height: 275px;
      }
    }
  }

  .thumbs-wrapper {
    margin: 1rem 0;
  }

  .thumbs {
    display: flex;
    margin: 0;
    padding-left: 0;

    .thumb {
      width: 100px !important;
      height: 92px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(9, 30, 66, 0.14);
      padding: 0.5rem;

      &.selected {
        border: 1px solid #1d2125;
      }

      img {
        width: 100%;
        // height: 100%;
      }
    }

    @media (max-width: ${globalStyles.breackPoints.md}) {
      .thumb {
        width: 70px !important;
        height: 64px;
      }
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      .thumb {
        width: 67px !important;
        height: 67px;
      }
    }
  }
`;

interface IGallery {
  url: string;
}

interface IProps {
  slider: IGallery[];
  title: string
}

const OneSlider: FC<IProps> = ({ slider, title }) => {
  return (
    <CarouselContainer>
      <Fancybox
        options={{
          Carousel: {
            infinite: true,
          },
        }}
      >
        <Carousel
          swipeable
          emulateTouch
          showArrows
          infiniteLoop={false}
          showStatus={false}
          stopOnHover={false}
          showIndicators={true}
          showThumbs={true}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                className={`${
                  hasPrev ? "position-absolute" : "display-none"
                } control-arrow control-prev`}
                onClick={clickHandler}
              >
                <img alt={title} src={CarretRightSlider} />
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <div
                className={`${
                  hasNext ? "position-absolute" : "display-none"
                } control-arrow control-next`}
                onClick={clickHandler}
              >
                <img alt={title} src={CarretRightSlider} />
              </div>
            );
          }}
        >
          {slider.map((item) => (
            <a
              className="single-slide"
              key={item.url}
              data-fancybox="gallery"
              href={item.url}
            >
              <img src={item.url} alt={item.url} />
              <a href={item.url} className="zoom">
                <img alt={title} src={ZoomIcon} />
              </a>
            </a>
          ))}
        </Carousel>
      </Fancybox>
    </CarouselContainer>
  );
};

export default OneSlider;
