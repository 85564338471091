import { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { API } from "services/API/API";
import { AboutContainer } from "./styled";
import { SingleBlogContainer } from "pages/Blog/styled";

const About = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({
    title: "",
    cover: {
      url: "",
    },
    meta_description: "",
    long_description: "",
  });

  useEffect(() => {
    API.about.getAbout(id).then((res) => {
      setData(res);
    });
  }, [i18n.language, id]);

  return (
    <DocumentMeta
      title={`${data?.title} | ${ProjectTitle}`}
      description={data?.meta_description}
    >
      <AboutContainer className="container">
        <SingleBlogContainer>
          <h1 className="text-center">{data?.title}</h1>
          {data?.cover?.url && (
            <img
              className="mb-200 mb-t-400"
              src={data?.cover?.url}
              alt={data?.title}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: data.long_description }} />
        </SingleBlogContainer>
      </AboutContainer>
    </DocumentMeta>
  );
};

export default About;
