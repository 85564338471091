import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IHomeBlog } from "types/home";
import { removeHtmlTags } from "helpers";
import DiscoverMore from "components/styled/DiscoverMore";
import { SubTitle } from "components/styled/SubTitle";
import BlogCard from "../blogCard";

interface IProps {
  blogs: IHomeBlog[];
}

const HomeBlogs: FC<IProps> = ({ blogs }) => {
  const { t } = useTranslation();

  const newsData = blogs.map((item) => {
    return {
      img: item.media?.url,
      url: item.slug,
      title: item.title,
      description: removeHtmlTags(item.short_description),
    };
  });

  return (
    <div>
      <SubTitle center>{t("blog")}</SubTitle>

      <div className="row home-blogs">
        {!!newsData[0] && (
          <div className="col-3 col-md-6 col-sm-12">
            <BlogCard isSmall {...newsData[0]} />
          </div>
        )}
        {!!newsData[1] && (
          <div className="col-3 col-md-6 col-sm-12">
            <BlogCard isSmall {...newsData[1]} />
          </div>
        )}
        {!!newsData[2] && (
          <div className="col-3 col-md-6 col-sm-12">
            <BlogCard isSmall {...newsData[2]} />
          </div>
        )}

        {!!newsData[3] && (
          <div className="col-3 col-md-6 col-sm-12">
            <BlogCard isSmall {...newsData[3]} />
          </div>
        )}
      </div>

      <div className="display-flex justify-center mt-600">
        <DiscoverMore url="/blog" />
      </div>
    </div>
  );
};

export default HomeBlogs;
