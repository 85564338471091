import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IHomeVlog } from "types/home";
import { removeHtmlTags } from "helpers";
import { SubTitle } from "components/styled/SubTitle";
import DiscoverMore from "components/styled/DiscoverMore";
import ListItem, { IListItem } from "../news";
import VideoSlider from "../videoSlider";

interface IProps {
  vlogs: IHomeVlog[];
}

const HomeVideos: FC<IProps> = ({ vlogs }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<IListItem[]>([]);
  const [openVideoSlider, setOpenVideoSlider] = useState(false);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (vlogs && vlogs.length > 0) {
      const arrayOfObjects = vlogs.map((item) => {
        return {
          title: item.title,
          img: item.media?.url,
          description: removeHtmlTags(item.description),
          url: "",
          youtubeUrl: item?.youtubeUrl,
        };
      });
      setData(arrayOfObjects);
    }
  }, [vlogs]);

  return (
    <>
      <VideoSlider
        imgs={vlogs.map((item) => (item.videoUrl ? item.videoUrl : item.img))}
        open={openVideoSlider}
        index={index}
        onClose={() => setOpenVideoSlider(false)}
      />

      <SubTitle center>{t("vlog")}</SubTitle>

      <div className="row">
        {data.map((item, index) => (
          <div key={item?.title + index} className="col-3 col-md-6 col-sm-12">
            <ListItem
              video
              withoutspace
              superSmallImg
              {...item}
              blogMax
              maxDescription
              onOpen={() => {
                setOpenVideoSlider(true);
                setIndex(index);
              }}
            />
          </div>
        ))}
      </div>

      <div className="display-flex justify-center mt-300">
        <DiscoverMore hidemore url="/vlog" />
      </div>
    </>
  );
};

export default HomeVideos;
