import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "components/styled/Button";
import { CallRequest } from "../request/styled";
import { ICategory, IModels } from "types/category";
import Checkbox from "../checkbox/checkbox";
import { currentLangUrl } from "helpers";

type Props = {
  open: boolean;
  categories: ICategory[];
  models: IModels[];
  filters: number[];
  close: () => void;
  setFilters: (data: number[]) => void;
};

function FilterModal({
  open,
  categories,
  models,
  filters,
  setFilters,
  close,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [initFilters, setInitFilters] = useState<number[]>([]);
  const [initProductType, setInitProductType] = useState<string>("");
  const [openSubCategories, setOpenSubcategories] = useState<{
    title: string;
    slug: string;
    data: ICategory[];
  } | null>(null);

  useEffect(() => {
    setInitFilters(filters);
    setInitProductType(id || "");
  }, [filters, id]);

  const onFilter = () => {
    if (initProductType) {
      navigate(`${currentLangUrl()}/products/${initProductType}`);
    }

    setFilters(initFilters);
    setInitFilters([]);
    setInitProductType("");
    close();
  };

  const onClearFilters = () => {
    setFilters([]);
    setInitFilters([]);
    setInitProductType("");
    navigate(`/products`);
    close();
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("fixed");
    } else {
      document.body.classList.remove("fixed");
    }
  }, [open]);

  return (
    <CallRequest style={{ display: open ? "block" : "none" }}>
      <div className="layout" onClick={() => close()} />

      <div className="modal">
        <div className="modal-header pt-150 pb-150 pl-300 pr-300 display-flex align-items-center justify-center">
          {t("filter")}
          <svg
            className="closeIcon"
            onClick={() => close()}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19.2806 18.2198C19.3502 18.2895 19.4055 18.3722 19.4432 18.4632C19.4809 18.5543 19.5003 18.6519 19.5003 18.7504C19.5003 18.849 19.4809 18.9465 19.4432 19.0376C19.4055 19.1286 19.3502 19.2114 19.2806 19.281C19.2109 19.3507 19.1281 19.406 19.0371 19.4437C18.9461 19.4814 18.8485 19.5008 18.7499 19.5008C18.6514 19.5008 18.5538 19.4814 18.4628 19.4437C18.3717 19.406 18.289 19.3507 18.2193 19.281L11.9999 13.0607L5.78055 19.281C5.63982 19.4218 5.44895 19.5008 5.24993 19.5008C5.05091 19.5008 4.86003 19.4218 4.7193 19.281C4.57857 19.1403 4.49951 18.9494 4.49951 18.7504C4.49951 18.5514 4.57857 18.3605 4.7193 18.2198L10.9396 12.0004L4.7193 5.78104C4.57857 5.64031 4.49951 5.44944 4.49951 5.25042C4.49951 5.05139 4.57857 4.86052 4.7193 4.71979C4.86003 4.57906 5.05091 4.5 5.24993 4.5C5.44895 4.5 5.63982 4.57906 5.78055 4.71979L11.9999 10.9401L18.2193 4.71979C18.36 4.57906 18.5509 4.5 18.7499 4.5C18.949 4.5 19.1398 4.57906 19.2806 4.71979C19.4213 4.86052 19.5003 5.05139 19.5003 5.25042C19.5003 5.44944 19.4213 5.64031 19.2806 5.78104L13.0602 12.0004L19.2806 18.2198Z"
              fill="#44546F"
            />
          </svg>
        </div>

        <div className="modal-body p-300">
          {!!categories?.length &&
            !openSubCategories &&
            categories.map((item) => (
              <div
                key={item.id}
                className="display-flex align-items-center justify-space-between"
                onClick={() =>
                  setOpenSubcategories({
                    title: item.title,
                    slug: item.slug,
                    data: item?.children || [],
                  })
                }
              >
                <div
                  className={`category pb-100 ${
                    item.slug === initProductType ? "active" : ""
                  }`}
                >
                  {item.title}
                </div>

                {!!item?.children?.length && (
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="CaretRight">
                      <path
                        id="Vector"
                        d="M13.2733 9.39792L7.64828 15.0229C7.59602 15.0752 7.53398 15.1166 7.46569 15.1449C7.39741 15.1732 7.32422 15.1878 7.25031 15.1878C7.1764 15.1878 7.10322 15.1732 7.03493 15.1449C6.96665 15.1166 6.90461 15.0752 6.85234 15.0229C6.80008 14.9707 6.75863 14.9086 6.73034 14.8403C6.70206 14.772 6.6875 14.6989 6.6875 14.6249C6.6875 14.551 6.70206 14.4779 6.73034 14.4096C6.75863 14.3413 6.80008 14.2792 6.85234 14.227L12.0801 8.99995L6.85234 3.77292C6.7468 3.66737 6.6875 3.52421 6.6875 3.37495C6.6875 3.22568 6.7468 3.08253 6.85234 2.97698C6.95789 2.87143 7.10105 2.81213 7.25031 2.81213C7.39958 2.81213 7.54273 2.87143 7.64828 2.97698L13.2733 8.60198C13.3256 8.65422 13.3671 8.71626 13.3954 8.78454C13.4237 8.85283 13.4383 8.92603 13.4383 8.99995C13.4383 9.07387 13.4237 9.14706 13.3954 9.21535C13.3671 9.28364 13.3256 9.34567 13.2733 9.39792Z"
                        fill="#44546F"
                      />
                    </g>
                  </svg>
                )}
              </div>
            ))}

          {!!openSubCategories && (
            <>
              <div
                className="display-flex align-items-center sub-category-conainer"
                onClick={() => setOpenSubcategories(null)}
              >
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="CaretRight">
                    <path
                      id="Vector"
                      d="M13.2733 9.39792L7.64828 15.0229C7.59602 15.0752 7.53398 15.1166 7.46569 15.1449C7.39741 15.1732 7.32422 15.1878 7.25031 15.1878C7.1764 15.1878 7.10322 15.1732 7.03493 15.1449C6.96665 15.1166 6.90461 15.0752 6.85234 15.0229C6.80008 14.9707 6.75863 14.9086 6.73034 14.8403C6.70206 14.772 6.6875 14.6989 6.6875 14.6249C6.6875 14.551 6.70206 14.4779 6.73034 14.4096C6.75863 14.3413 6.80008 14.2792 6.85234 14.227L12.0801 8.99995L6.85234 3.77292C6.7468 3.66737 6.6875 3.52421 6.6875 3.37495C6.6875 3.22568 6.7468 3.08253 6.85234 2.97698C6.95789 2.87143 7.10105 2.81213 7.25031 2.81213C7.39958 2.81213 7.54273 2.87143 7.64828 2.97698L13.2733 8.60198C13.3256 8.65422 13.3671 8.71626 13.3954 8.78454C13.4237 8.85283 13.4383 8.92603 13.4383 8.99995C13.4383 9.07387 13.4237 9.14706 13.3954 9.21535C13.3671 9.28364 13.3256 9.34567 13.2733 9.39792Z"
                      fill="#44546F"
                    />
                  </g>
                </svg>

                <div className="category">{openSubCategories?.title}</div>
              </div>
              <div
                className={`sub-category display-flex align-items-center justify-space-between pb-300 pl-600 pr-600 ${
                  initProductType === openSubCategories.slug ? "active" : ""
                }`}
              >
                <div onClick={() => setInitProductType(openSubCategories.slug)}>
                  {t("showAll")}
                </div>
              </div>
              {openSubCategories?.data?.map((sub) => (
                <div
                  key={sub.id}
                  className={`sub-category display-flex align-items-center justify-space-between pb-300 pl-600 pr-600 ${
                    initProductType === sub.slug ? "active" : ""
                  }`}
                >
                  <div onClick={() => setInitProductType(sub.slug)}>
                    {sub.title}
                  </div>
                </div>
              ))}
            </>
          )}

          {!!models.length && (
            <>
              <h3>{t("model")}</h3>
              {models.map((item) => (
                <Checkbox
                  key={item.id}
                  label={item.title}
                  checked={initFilters.includes(item.id)}
                  onCheck={() =>
                    setInitFilters(
                      initFilters.includes(item.id)
                        ? initFilters.filter((i) => i !== item.id) || []
                        : [...initFilters, item.id]
                    )
                  }
                />
              ))}
            </>
          )}
        </div>

        <div className="modal-footer p-300 gap-200 display-flex">
          <Button
            size="lg"
            variant="light"
            onClick={onClearFilters}
            style={{ flex: 1 }}
          >
            {t("clear")}
          </Button>
          <Button size="lg" onClick={onFilter} style={{ flex: 1 }}>
            {t("filterAction")}
          </Button>
        </div>
      </div>
    </CallRequest>
  );
}

export default FilterModal;
