import axiosInstance from "..";

const getCategories = () => {
  return axiosInstance.get(`products/new/getcategories`).then((res) => {
    return res.data;
  });
};

const getProjects = (type: string, params?: any, models?: string) => {
  return axiosInstance
    .get(`products/${type}${models ? `?${models}` : ""}`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

// const getProjectsByCategory = (slug: string, type: string) => {
//   return axiosInstance.get(`products/${type}/${slug}`).then((res) => {
//     return res.data;
//   });
// };

const getSingeProject = (slug: string, type: string) => {
  return axiosInstance.get(`products/${type}/show/${slug}`).then((res) => {
    return res.data;
  });
};

const getSimilarProducts = (id: string, type: string) => {
  return axiosInstance.get(`products/${type}/getsimilars/${id}`).then((res) => {
    return res.data;
  });
};

const sendMobile = (phone: number | string, model_id: number | boolean) => {
  return axiosInstance
    .post("phonerequest/product_store", {
      phone: phone,
      model_id: model_id,
    })
    .then((res) => {
      return res.data;
    });
};

export const projects = {
  getCategories,
  getProjects,
  getSingeProject,
  sendMobile,
  getSimilarProducts,
  // getProjectsByCategory,
};
