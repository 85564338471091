import { globalStyles } from "consts/spaces";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import CaretDown from "assets/icons/CaretDown.svg";
import { useOnClickOutside } from "helpers";

// Define the types for your dropdown items
interface DropdownItem {
  value: string;
  label: string;
}

// Props for the Dropdown component
interface IProps {
  items: DropdownItem[];
  onSelect: (value: string) => void;
  label: string;
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  padding: ${globalStyles.spaces[150]} 0;
  min-width: 125px;
`;

const DropdownButton = styled.button`
  background-color: transparent;
  font-size: ${globalStyles.fonts.b20};
  color: ${(props) => props.theme.colorTextSubtlest};
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 0;

  img {
    transition: all 0.3s ease-in-out;
    padding-left: ${globalStyles.spaces[100]};
    float: right;
    position: relative;
    top: 3px;

    &.active {
      transform: rotate(180deg);
      padding-left: 0;
      padding-right: ${globalStyles.spaces[100]};
    }
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0px;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px -8px #172b4d33;
  border: 1px solid #091e4224;
  z-index: 3;
  max-height: 200px;
  overflow: scroll;
`;

const DropdownListItem = styled.li`
  cursor: pointer;
  font-size: ${globalStyles.fonts.p14};
  color: ${(props) => props.theme.colorTextSubtlest};
  padding: ${globalStyles.spaces[75]} ${globalStyles.spaces[50]};
`;

const Dropdown: React.FC<IProps> = ({ items, onSelect, label }) => {
  const node = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(null);

  const handleItemClick = (item: DropdownItem | number) => {
    if (typeof item === "number") {
      setSelectedItem(null);
      onSelect("");
      setIsOpen(false);
    } else {
      setSelectedItem(item);
      onSelect(item.value);
      setIsOpen(false);
    }
  };

  useOnClickOutside(node, () => setIsOpen(false));

  return (
    <DropdownContainer ref={node}>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        {selectedItem ? selectedItem.label : label}
        <img className={isOpen ? "active" : ""} src={CaretDown} alt="carret" />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          <DropdownListItem onClick={() => handleItemClick(0)}>
            {label}
          </DropdownListItem>
          {items.map((item) => (
            <DropdownListItem
              key={item.value}
              onClick={() => handleItemClick(item)}
            >
              {item.label}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
